@import "../../../../assets/styles/variables";


.react-calendar {
    width: 450px;
    z-index: 4;

    @include breakpoint(mobileonly) {
        width: 90vw;
        left: 20px;
    }
}

.checkPrice {
    padding: 1em;
    border: 2px solid lightgray;

    .checkPriceMain {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include breakpoint(untilTablet) {
            flex-direction: column;
            align-items: start;
        }
    }

    h4 {
        margin-bottom: 1em;
        font-size: 1.35em;
    }

    .formGroup {
        margin-bottom: .5em;
    }

    .formCategories {
        position: relative;

        .icon {
            position: absolute;
            left: 1em;
            top: 50%;
            transform: translateY(-50%);
        }

        select {
            padding: 0.75em 3em;
            font-weight: bold;
        }
    }

    .btnWrapper {
        width: fit-content;

        @include breakpoint(untilTablet) {
            width: 100%;
        }
    }

    .btn {
        font-size: 1.15em;
        padding: 0.5em 1.5em;

        @include breakpoint(untilTablet) {
            font-size: 1em;
            padding: .5rem 1rem;
            width: 100%;
            margin-top: 1rem;
        }
    }

    .formDate {
        margin-right: 0.5em;
        max-width: 300px;

        input {
            font-size: 16px;
        }

        @include breakpoint(untilTablet) {
            width: 100%;
            margin-right: 12px;
        }
    }

    .formIcon {
        position: relative;
        margin-right: 0.5em;
        max-width: 102px;

        .icon {
            position: absolute;
            left: 0.5em;
            top: 48%;
            transform: translateY(-50%);

            img {
                height: 20px;
                width: auto;
            }
        }

        @include breakpoint(untilTablet) {
            margin-bottom: 0 !important;
            max-width: 85px;
        }

        input {
            padding-left: 2em;
            font-size: 16px;
        }
    }
}