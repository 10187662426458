@import "../../assets/styles/variables";

.productDetail {
  .container {
    padding: 5vh 1rem;
    padding-top: 30px;

    .description {
      padding: 2em 0;
      font-weight: 500;
      color: #1a1a1a;
    }

    .back {
      font-size: 1em;
      margin-bottom: 1rem;
      display: block;

      @include breakpoint(untilTablet) {
        font-size: 0.85rem;
      }
    }

    .product {

      .skeletonWrapper {
        padding: 2rem;
      }

      .productWrapper {
        padding-top: 0;

        .serviceType {
          display: inline-block;
          padding: 0.5rem 2rem;
          padding-bottom: 0.75rem;
          margin-bottom: 1.5rem;
          background-color: $primary;
          color: white;
          font-family: 'Noto Serif JP', serif;
        }

        @include breakpoint(untilTablet) {
          .serviceType {
            font-size: 1rem;
            padding-left: 1rem;
          }
        }

        .title {
          text-align: center;
          font-weight: 500;
          font-size: 24px;
          display: flex;
          justify-content: center;
          margin-top: 2rem;

          @include breakpoint(tablet) {
            font-size: 28px;
          }
        }

        .productHeader {
          position: relative;

          .slick-slide img {
            margin: auto;
          }

          .slick-prev {
            left: 1.5rem;

            &::before {
              color: $primary;
            }
          }

          .slick-next {
            right: 1.5rem;

            &::before {
              color: $primary;
            }
          }

          @include breakpoint(untilTablet) {
            .slick-prev {
              left: -10px;
            }

            .slick-next {
              right: -10px;
            }
          }
        }

        .carousel {
          text-align: center;
          margin: 1rem 0;

          @include breakpoint(untilTablet) {
            >img {
              width: 100%;
            }
          }

          .slide img {
            height: 300px;
            object-fit: contain;

            @include breakpoint(desktop) {
              height: 500px;
            }
          }

          .image {
            max-height: 500px;
            width: 100%;
            object-fit: contain;
          }

          .slider-nav {
            .slick-track {
              justify-content: center;
            }

            .slick-slide {
              height: 50px;
              width: 50px !important;

              >div {
                height: 50px;
                width: 50px;
              }

              img {
                max-height: 50px;
                width: 50px;
                object-fit: cover;
              }
            }
          }
        }

        .carousel.carousel-slider .control-arrow {

          &:hover {
            background: rgba($color: #000000, $alpha: 0.3);

          }
        }

        .sectionTitle {
          margin-bottom: 1em;

          span {
            padding: 0;
            display: inline-block;
            font-size: 1.8em;
            font-weight: bold;
          }
        }

        .infoTitle {
          i {
            margin-right: .5em;
          }

          h3 {
            margin-bottom: 0;
            font-weight: bold;
            font-size: 1.6em;
            display: flex;
            align-items: center;
            justify-content: center;
            fill: #0080d2;
            font-weight: bold;
            font-size: 1.6em;
            margin-bottom: 1em;
            padding-top: 2em;

          }
        }

        .info {
          table {
            border-top: 1px solid #b1b6b7;
            border-bottom: 1px solid #b1b6b7;
          }

          a {
            color: $primary;
          }
        }

        tr {
          border-bottom: 1px solid #b1b6b7;

          td {
            padding: 0.8em;

            &:first-child {
              width: 14em;
              background: #b9d6fa;
              font-weight: bold;
              border-bottom: 1px solid white;
            }
          }
        }
      }

      @include breakpoint(untilTablet) {

        .skeletonWrapper {
          padding: 1rem;
        }

        .productWrapper {
          padding-top: 0;

          .carousel {
            .image {
              max-width: 100%;
            }
          }
        }
      }
    }
  }

  .map {
    padding: 4em 0;
  }
}