@import "../../assets/styles/variables";

.Invoice__wrapper {
  margin: 80px 0;
}

.wrapper {
  border: 2px solid $primary;

  .header {
    background: $primary;
    padding: 1rem;
    color: white;
    font-family: 'Noto Serif JP', serif;
  }

  .body {
    padding: 1rem;
  }

  .grey {
    padding: 1rem;
    background: rgb(226, 226, 226);
  }
}