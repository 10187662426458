@import "../../../../assets/styles/variables";

.item {
    margin-bottom: 2em;

    &.shopping {
        display: grid;
        grid-template-columns: 30% 68.5%;
        gap: 0 1em;

        &:not(:last-child) {
            padding-bottom: 2em;
            border-bottom: 1px solid #d1d1d1;
            margin-bottom: 2em;
        }

        .title {
            display: none;
        }

        .desc {
            display: block;
            overflow: hidden;
            max-height: 120px;
            line-height: 20px;
            position: relative;

            &::after {
                content: '';
                text-align: right;
                position: absolute;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 1.2em;
                background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white 90%);
            }
        }


        .image {
            width: 100%;
            padding-top: 66%;
        }

        .titleShop {
            font-weight: 600;
            line-height: 1.4em;
            margin-bottom: .5em;
            color: $primary;
            cursor: pointer;
            font-size: 1.35rem;

            @include breakpoint(desktop) {
                font-size: 1.5rem;

            }
        }

        .info {
            margin-top: 0;
        }

        .buttonWrapper {
            justify-content: end;
        }

        @include breakpoint(untilTablet) {
            grid-template-columns: 100%;

            .image {
                margin-bottom: .5em;
            }

            .info {
                height: 100%;
            }

            .titleShop {

                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }

            .buttonWrapper {
                justify-content: start;
            }
        }
    }

    .image {
        position: relative;
        display: block;
        width: 100%;
        margin: 0;
        padding-top: 100%;
        object-fit: cover;
        object-position: center;
        background: rgba(0, 0, 0, 0.05) url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 50 50"><path fill="rgba(0,0,0,0.2)" d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z" transform="rotate(200.584 25 25)"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"></animateTransform></path></svg>') center center no-repeat;
        background-size: 3em;
        overflow: hidden;
        background: opacity 0.2s ease-out;
        cursor: pointer;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: inherit;
            object-position: inherit;
            opacity: 1;
            transition: opacity 0.2s ease-out;
        }
    }

    .info {
        margin-top: 1em;

        @include breakpoint(untilTablet) {
            height: 6em;
        }
    }

    .title,
    .address,
    .price,
    .desc {
        margin-bottom: 0.5rem;
        font-size: 0.9em;
    }

    .price {
        background: $secondary;
        color: black;
        font-weight: bold;
        padding: .25rem .75rem;
        border-radius: 6px;
        display: inline-flex;
    }

    .title {
        position: relative;
        padding: 0.8em;
        color: #fff;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: bold;
        z-index: 1;
        margin-top: -2.6em;
        font-size: 1.25rem;
        color: $primary;
        cursor: pointer;

        @include breakpoint(untilTablet) {
            font-size: 1em;
        }
    }

    .address {
        color: rgb(105, 105, 105);
        font-weight: 500;
    }

    .desc {
        max-height: 45px;
        font-weight: 400;
        position: relative;
        font-size: 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        @include breakpoint(untilTablet) {
            max-height: 60px;
        }

        div,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
            font-size: 16px !important;
        }
    }

    .buttonWrapper {
        margin-top: 0.5em;
        padding-top: 0.5em;
        display: flex;
        justify-content: center;

        .btn {
            color: #fff;
            padding: 0.5em 0.8em;
            display: flex;
            align-items: center;
            background: $primary;
            border: none;

            svg {
                path {
                    fill: white;
                }
            }

            .view-svg {
                margin-right: .5rem;
                margin-top: -4px;
            }
        }
    }
}

.wishlistWrapper {
    border: 3px solid $primary;
    position: sticky;
    top: 124px;

    @include breakpoint(untilTablet) {
        display: none;
    }

    .wishlistTitle {
        color: white;
        text-align: center;
        background-color: $primary;
        padding: .5rem;
        font-size: 1.15rem;
        font-weight: bold;
    }

    .wishlistItems {
        padding: 1rem .75rem;
        overflow: auto;
        height: 70vh;

        .title {
            font-weight: bold;
            color: $primary;
            margin-bottom: .5rem;
        }

        .wishlistEmpty {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
        }

        .itemWishlist {
            display: flex;
            padding: 0;
            margin-bottom: 1.5rem;
            cursor: default;
            position: relative;

            .removeWishlist {
                color: white;
                background-color: red;
                height: 24px;
                width: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                font-size: 14px;
                cursor: pointer;
                margin-left: .5rem;
            }

            &:last-child {
                margin-bottom: 0;
            }

            .imageContainer {

                width: 30%;
                padding-right: .5rem;
            }

            .info {
                width: 70%;
                min-height: auto;
                padding: 0;

                >* {
                    margin-bottom: .25rem;
                }

                .title,
                .desc {
                    white-space: normal;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    width: 100%;
                    height: auto;
                    font-size: 1rem;
                }

                .price {
                    font-weight: bold;
                }

                .desc {
                    margin-bottom: 0;
                    font-size: .85rem;
                }
            }
        }
    }

    .wishlistButton {
        padding: .5rem .75rem;
    }
}