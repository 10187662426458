@import "../../assets/styles/variables";

.gnav li.js-link_match_location {
    background-size: 1px 6px;
    background-image: linear-gradient(to bottom, #D50B13 2px, transparent 4px);
    background-repeat: repeat-y;
    background-position: right top;
    width: 130px;
    height: 60px;
    font-weight: bold;
    text-align: center;
    font-size: 16px;

    button {
        font-weight: bold;
    }

    &:last-child {
        background-image: none;
    }
}

.header {
    font-family: 'Noto Sans JP', sans-serif;
}

.header .cv li {
    a {
        color: #fff;
        display: block;
        width: 120px;
        height: 112px;
        background: $primary;
        text-align: center;
        font-weight: bold;
        margin-right: 16px;
        padding-top: 12px;
        line-height: 1.3;
        border-radius: 0 0 60px 60px;
        font-size: 16px;

        img {
            margin: auto;
            margin-bottom: 5px;
        }
    }

    &:last-child {
        a {
            background: $secondary;
        }
    }
}

/* hamburger
------------------------------------------------ */
.header .hamburger {
    display: block;
    height: 47px;
    margin: 28px 24px 0;
    margin-right: 16px;
    position: relative;
    z-index: 50;
    width: 40px;
    border: none;
    background-color: transparent;
}

.header .hamburger.-active {
    position: fixed;
    top: 0;
    right: 0;
}

.header .hamburger.-active .line {
    background-color: transparent;
}

.header .hamburger.-active .line:before {
    top: 0;
    transform: rotate(45deg);
    background-color: #fff;
}

.header .hamburger.-active .line:after {
    top: 0;
    transform: rotate(-45deg);
    background-color: #fff;
}

.header .hamburger.-active .text:before {
    content: 'Close';
    color: #fff;
}

.header .hamburger .line {
    display: block;
    height: 2px;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 26px;
    background-color: #D50B13;
    transition: 0.4s;
}

.header .hamburger .line:before,
.header .hamburger .line:after {
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
    background-color: #D50B13;
    transition: inherit;
}

.header .hamburger .line:before {
    top: -10px;
}

.header .hamburger .line:after {
    top: 10px;
}

.header .hamburger .text {
    position: absolute;
    bottom: -3px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
}

.header .hamburger .text:before {
    content: "Menu";
    text-align: center;
    color: #D50B13;
    font-size: 14px;
    font-weight: 900;
    font-family: "Red Hat Display", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
}

.x-small {
    font-size: 78%;
}

.small {
    font-size: 87%;
}

.header a:hover,
.header button:hover {
    opacity: 0.5;
}

#gmenu {
    position: fixed;
    background: #fff;
    width: 100%;
    height: 100vh;
    height: 100dvh;
    transition: all .3s;
    top: 0;
    z-index: 30;
}

#gmenu .inner.active {
    right: 0;
}

.gmenu .inner {
    background: #D50B13;
    transition: all .8s;

    height: 2000px;
    width: 1230px;
    position: fixed;
    right: -100vw;
    top: 50%;
    border-radius: 1000px 0 0 1000px / 1000px 0 0 1000px;
    transform: translateY(-50%);
}

.gmenu .inner .menu {
    width: 760px;
    height: 510px;
    position: absolute;
    top: 0;
    left: 50px;
    right: 0;
    bottom: 0;
    margin: auto;
}

.header nav,
.footer nav {
    display: flex;
    text-align: left;
    font-weight: bold;
    font-size: 14px;
}

.gmenu .btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
}

.header .gmenu a,
.header .gmenu a:link,
.header .gmenu a:active,
.header .gmenu a:hover,
.header .gmenu a:visited,
.header .gmenu dt {
    color: white;
}

@media screen and (min-width: 600px) {
    .sp {
        display: none;
    }

}

.header .btn li {
    margin-left: 40px;
}

.header .btn .icon {
    fill: #D50B13;
}

.header .btn li:last-child .icon {
    fill: #008858;
}

.header .sns {
    display: flex;
    align-items: center;
}

.header .sns .icon {
    fill: #fff;
    width: 24px;
    height: 24px;
}

.header .lang {
    margin-left: 38px;
    font-size: 16px;
    font-family: "Red Hat Display", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
    width: 158px;
    min-height: 48px;
    line-height: 48px;
    text-align: center;
    color: #D50B13;
    border-radius: 24px;
    cursor: pointer;
    background: #fff;

    dt {
        color: #D50B13;
    }
}

@media screen and (max-width: 599px) {
    .header .gmenu .inner {
        border-radius: 0 0 500px 500px / 0 0 300px 300px;
        border-radius: 0;
        transform: none;
        top: 0;
        height: 100vh;
        height: 100dvh;
        overflow-y: auto;
        width: 100%;
    }

    .header .gmenu .inner .menu {
        left: 0;
        height: 100vh;
        width: 100%;
        top: 80px;
    }

    .header .btn {
        display: block;
        position: absolute;
        top: 80px;
        left: 0;
        margin-top: 0;
        width: 100%;
    }

    .header .btn li {
        margin: 0 auto 16px;
        width: 85%;
    }

    .header .logo {
        margin: 9px 16px;
        display: block;
        width: 92px;
        height: 42px;
    }

    .header nav {
        flex-wrap: wrap;
        margin: 200px 25px 0;
        justify-content: space-between;
        font-size: 12px;
    }

    .header .utility,
    .footer .utility {
        font-size: 10px;
        text-align: center;
        margin: 20px auto 0;
        justify-content: center;
    }

    #gmenu {
        background-color: transparent;
    }

    .header .hamburger .line:before {
        top: -5px;
    }

    .header .hamburger .line:after {
        top: 5px;
    }

    .header .hamburger .text:before {
        font-size: 12px;
    }

    .header .hamburger {
        margin: 15px 20px 0 0;
        height: 35px;
    }
}