@media (max-width: 767.98px) {

  .wrap,
  .wrap-min {
    padding: 0 .75rem;
  }
}

.of-cover {
  object-fit: cover;
  object-position: center;
}

.f-11px {
  font-size: 11px;
}

.position-sticky {
  position: sticky;
  top: 0;
}

.modal-header {
  border-bottom: none !important;
  padding-bottom: 0;
}

.modal-footer {
  border-top: none !important;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.top {
  position: fixed;
  bottom: 80px;
  right: 40px;
  z-index: 100;
  cursor: pointer;

  img {
    width: 60px;
    height: 60px;
  }

  @include breakpoint(untilTablet) {
    bottom: 50px;
    right: 20px;

    img {
      width: 40px;
      height: 40px;
    }
  }
}

.btn {
  border-radius: 0;
}

#breadcrumbs {
  font-size: 14px;
  padding: 1rem 0;
  max-width: 1200px;
  margin: auto;

  a {
    text-decoration: underline;
    color: #666;
    margin-right: .5rem;
  }

  span {
    margin-right: .5rem;
    color: #666;
  }
}

svg {
  width: 1em;
  height: 1em;
}

.react-calendar {
  width: 200px;
  position: absolute;

  &.hide {
    display: none;
  }
}

.react-calendar__navigation button {
  min-width: 24px;
}

.react-calendar__tile {
  line-height: 10px;
}

.form-control[readonly] {
  background-color: white;
  cursor: pointer;
}

.btn {
  border-radius: 5px;

  &.btn-white {
    padding: 0.5em;
    background: #fff;
    border: 1px solid #7f8788;
    border-radius: 5px;
    fill: #7f8788;
    text-align: center;
    cursor: pointer;
  }
}

@media (min-width: 1400px) {

  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}


.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 16px;
  text-align: left;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;

  &.active {
    display: block;
  }

}

.hidden {
  display: none;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

input[type=number]::-webkit-inner-spin-button {
  opacity: 1
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e');
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

.modal {
  display: none;
  height: 100%;
  left: 0;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1055;
}

.modal.fade .modal-dialog {
  -webkit-transform: translateY(-50px);
  transform: translateY(-50px);
  transition: -webkit-transform .3s ease-out;
  transition: transform .3s ease-out;
  transition: transform .3s ease-out, -webkit-transform .3s ease-out;
}

.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}

@media (min-width: 992px) {

  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

.modal-dialog-centered {
  align-items: center;
  display: flex;
  min-height: calc(100% - 1rem);
}

.modal-dialog {
  margin: .5rem;
  pointer-events: none;
  position: relative;
  width: auto;
}

.modal-dialog-centered {
  min-height: calc(100% - 3.5rem);
}

.modal-dialog {
  margin: 1.75rem auto;
  max-width: 800px;
}

.modal-content {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: .3rem;
  display: flex;
  flex-direction: column;
  outline: 0;
  pointer-events: auto;
  position: relative;
  width: 100%;
  margin: 0 1rem;
}

.modal-body,
.modal-footer {
  padding: 1rem;
}

.modal-backdrop {
  background-color: #000;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1050;
}

.modal-backdrop.show {
  opacity: .5;
}

.modal-header {
  align-items: center;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  padding: 1rem;
}

.modal-title {
  line-height: 1.5;
  margin-bottom: 0;
  font-size: 1.5rem;
}

.btn-close {
  background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E") 50% / 1em auto no-repeat;
  border: 0;
  border-radius: .25rem;
  box-sizing: content-box;
  color: #000;
  height: 1em;
  opacity: .5;
  padding: .25em;
  width: 1em;
}

.imageSliders .modal-content .btn-close {
  background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E") 50% / 1em auto no-repeat;
  opacity: 1;
  padding: .5em;
  position: relative;
}

.imageSliders .modal-content .btn-close:before {
  background-color: #fff;
  border-radius: 50%;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, .05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, .1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, .075);
  border-color: #dee2e6;
  color: #212529;
  margin-bottom: 1rem;
  vertical-align: top;
  width: 100%
}

.table>:not(caption)>*>* {
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  padding: .5rem
}

.table>tbody {
  vertical-align: inherit
}

.table>thead {
  vertical-align: bottom
}

.table>:not(:first-child) {
  border-top: 2px solid
}

.caption-top {
  caption-side: top
}

.table-sm>:not(caption)>*>* {
  padding: .25rem
}

.table-bordered>:not(caption)>* {
  border-width: 1px 0
}

.table-bordered>:not(caption)>*>* {
  border-width: 0 1px
}

.table-borderless>:not(caption)>*>* {
  border-bottom-width: 0
}

.table-borderless>:not(:first-child) {
  border-top-width: 0
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color)
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color)
}

.table-hover>tbody>tr:hover>* {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color)
}

.table-primary {
  --bs-table-bg: #f7d5d1;
  --bs-table-striped-bg: #ebcac7;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dec0bc;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e4c5c1;
  --bs-table-hover-color: #000;
  border-color: #dec0bc;
  color: #000
}

.table-secondary {
  --bs-table-bg: #f5e3e3;
  --bs-table-striped-bg: #e9d8d8;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dcc;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e3d2d2;
  --bs-table-hover-color: #000;
  border-color: #dcc;
  color: #000
}

.table-success {
  --bs-table-bg: #d1e7dd;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  border-color: #bcd0c7;
  color: #000
}

.table-info {
  --bs-table-bg: #cff4fc;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  border-color: #badce3;
  color: #000
}

.table-warning {
  --bs-table-bg: #fff3cd;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  border-color: #e6dbb9;
  color: #000
}

.table-danger {
  --bs-table-bg: #f8d7da;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  border-color: #dfc2c4;
  color: #000
}

.table-light {
  --bs-table-bg: #fff;
  --bs-table-striped-bg: #f2f2f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6e6e6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ececec;
  --bs-table-hover-color: #000;
  border-color: #e6e6e6;
  color: #000
}

.table-dark {
  --bs-table-bg: #212529;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  border-color: #373b3e;
  color: #fff
}

.table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto
}

@media(max-width: 575.98px) {
  .table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto
  }
}

@media(max-width: 767.98px) {
  .table-responsive-md {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto
  }
}

@media(max-width: 991.98px) {
  .table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto
  }
}

@media(max-width: 1199.98px) {
  .table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto
  }
}

@media(max-width: 1399.98px) {
  .table-responsive-xxl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto
  }
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: calc(var(--bs-gutter-x)*-.5);
  margin-right: calc(var(--bs-gutter-x)*-.5);
  margin-top: calc(var(--bs-gutter-y)*-1)
}

.row>* {
  flex-shrink: 0;
  margin-top: var(--bs-gutter-y);
  max-width: 100%;
  padding-left: calc(var(--bs-gutter-x)*.5);
  padding-right: calc(var(--bs-gutter-x)*.5);
  width: 100%
}

.col {
  flex: 1 0
}

.row-cols-auto>* {
  flex: 0 0 auto;
  width: auto
}

.row-cols-1>* {
  flex: 0 0 auto;
  width: 100%
}

.row-cols-2>* {
  flex: 0 0 auto;
  width: 50%
}

.row-cols-3>* {
  flex: 0 0 auto;
  width: 33.3333333333%
}

.row-cols-4>* {
  flex: 0 0 auto;
  width: 25%
}

.row-cols-5>* {
  flex: 0 0 auto;
  width: 20%
}

.row-cols-6>* {
  flex: 0 0 auto;
  width: 16.6666666667%
}

.col-auto {
  flex: 0 0 auto;
  width: auto
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%
}

.col-3 {
  flex: 0 0 auto;
  width: 25%
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%
}

.col-6 {
  flex: 0 0 auto;
  width: 50%
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%
}

.col-9 {
  flex: 0 0 auto;
  width: 75%
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%
}

.col-12 {
  flex: 0 0 auto;
  width: 100%
}

@media(min-width: 992px) {
  .col-lg {
    flex: 1 0
  }

  .row-cols-lg-auto>* {
    flex: 0 0 auto;
    width: auto
  }

  .row-cols-lg-1>* {
    flex: 0 0 auto;
    width: 100%
  }

  .row-cols-lg-2>* {
    flex: 0 0 auto;
    width: 50%
  }

  .row-cols-lg-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%
  }

  .row-cols-lg-4>* {
    flex: 0 0 auto;
    width: 25%
  }

  .row-cols-lg-5>* {
    flex: 0 0 auto;
    width: 20%
  }

  .row-cols-lg-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%
  }

  .offset-lg-0 {
    margin-left: 0
  }

  .offset-lg-1 {
    margin-left: 8.33333333%
  }

  .offset-lg-2 {
    margin-left: 16.66666667%
  }

  .offset-lg-3 {
    margin-left: 25%
  }

  .offset-lg-4 {
    margin-left: 33.33333333%
  }

  .offset-lg-5 {
    margin-left: 41.66666667%
  }

  .offset-lg-6 {
    margin-left: 50%
  }

  .offset-lg-7 {
    margin-left: 58.33333333%
  }

  .offset-lg-8 {
    margin-left: 66.66666667%
  }

  .offset-lg-9 {
    margin-left: 75%
  }

  .offset-lg-10 {
    margin-left: 83.33333333%
  }

  .offset-lg-11 {
    margin-left: 91.66666667%
  }

  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0
  }

  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0
  }

  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem
  }

  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem
  }

  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem
  }

  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem
  }

  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem
  }

  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem
  }

  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem
  }

  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem
  }

  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem
  }

  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem
  }
}

.form-label {
  margin-bottom: .5rem
}

.col-form-label {
  font-size: inherit;
  line-height: 1.5;
  margin-bottom: 0;
  padding-bottom: calc(.375rem + 1px);
  padding-top: calc(.375rem + 1px)
}

.col-form-label-lg {
  font-size: 1.25rem;
  padding-bottom: calc(.5rem + 1px);
  padding-top: calc(.5rem + 1px)
}

.col-form-label-sm {
  font-size: .875rem;
  padding-bottom: calc(.25rem + 1px);
  padding-top: calc(.25rem + 1px)
}

.form-text {
  color: #6c757d;
  font-size: .875em;
  margin-top: .25rem
}

.form-control {
  -webkit-appearance: none;
  appearance: none;
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  color: #212529;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: .375rem .75rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  width: 100%
}

@media(prefers-reduced-motion:reduce) {
  .form-control {
    transition: none
  }
}

.form-control[type=file] {
  overflow: hidden
}

.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer
}

.form-control:focus {
  background-color: #fff;
  border-color: #ec968d;
  box-shadow: 0 0 0 .25rem rgba(217, 45, 27, .25);
  color: #212529;
  outline: 0
}

.form-control::-webkit-date-and-time-value {
  height: 1.5em
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1
}

.form-control::file-selector-button {
  -webkit-margin-end: .75rem;
  background-color: #e9ecef;
  border: 0 solid;
  border-color: inherit;
  border-inline-end-width: 1px;
  border-radius: 0;
  color: #212529;
  margin: -.375rem -.75rem;
  margin-inline-end: .75rem;
  padding: .375rem .75rem;
  pointer-events: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none
  }

  .form-control::file-selector-button {
    transition: none
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3
}

.form-control::-webkit-file-upload-button {
  -webkit-margin-end: .75rem;
  background-color: #e9ecef;
  border: 0 solid;
  border-color: inherit;
  border-inline-end-width: 1px;
  border-radius: 0;
  color: #212529;
  margin: -.375rem -.75rem;
  margin-inline-end: .75rem;
  padding: .375rem .75rem;
  pointer-events: none;
  -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none
  }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3
}

.form-control-plaintext {
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
  color: #212529;
  display: block;
  line-height: 1.5;
  margin-bottom: 0;
  padding: .375rem 0;
  width: 100%
}

.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
  padding-left: 0;
  padding-right: 0
}

.form-control-sm {
  border-radius: .2rem;
  font-size: .875rem;
  min-height: calc(1.5em + .5rem + 2px);
  padding: .25rem .5rem
}

.form-control-sm::file-selector-button {
  -webkit-margin-end: .5rem;
  margin: -.25rem -.5rem;
  margin-inline-end: .5rem;
  padding: .25rem .5rem
}

.form-control-sm::-webkit-file-upload-button {
  -webkit-margin-end: .5rem;
  margin: -.25rem -.5rem;
  margin-inline-end: .5rem;
  padding: .25rem .5rem
}

.form-control-lg {
  border-radius: .3rem;
  font-size: 1.25rem;
  min-height: calc(1.5em + 1rem + 2px);
  padding: .5rem 1rem
}

.form-control-lg::file-selector-button {
  -webkit-margin-end: 1rem;
  margin: -.5rem -1rem;
  margin-inline-end: 1rem;
  padding: .5rem 1rem
}

.form-control-lg::-webkit-file-upload-button {
  -webkit-margin-end: 1rem;
  margin: -.5rem -1rem;
  margin-inline-end: 1rem;
  padding: .5rem 1rem
}

textarea.form-control {
  min-height: calc(1.5em + .75rem + 2px)
}

textarea.form-control-sm {
  min-height: calc(1.5em + .5rem + 2px)
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px)
}

.form-control-color {
  height: auto;
  padding: .375rem;
  width: 3rem
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer
}

.form-control-color::-moz-color-swatch {
  border-radius: .25rem;
  height: 1.5em
}

.form-control-color::-webkit-color-swatch {
  border-radius: .25rem;
  height: 1.5em
}