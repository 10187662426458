@import "../../../../assets/styles/variables";

.infoSection {
    display: flex;
    align-items: center;
    border: 1px solid lightgray;
    flex-wrap: wrap;

    .infoItem {
        padding: 1rem;
    }

    .infoItem:first-child {
        padding: 1rem;
        background: $primary;
        color: white;
        width: 100%;
        border-bottom: 1px solid lightgray;

        @include breakpoint(desktop) {
            width: 25%;
            margin-right: 1rem;
            border-right: 1px solid lightgray;
            border-bottom: none;
        }
    }
}