@import "../../../../assets/styles/variables";

.availableProducts {
  .items {

    .productItem {
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid lightgray;

      .col-lg-11 {
        width: 88%;

        @include breakpoint(untilTablet) {
          width: 100%;
        }
      }

      .col-lg-1 {
        width: 12%;

        @include breakpoint(untilTablet) {
          width: 100%;
        }
      }

      .info {
        display: flex;
        gap: 1.5rem;

        @include breakpoint(untilTablet) {
          flex-direction: column;
        }

        .actions {
          display: flex;
          align-items: center;
          gap: 1rem;
        }

        .qty {
          align-items: center;
          margin-bottom: 0.5rem;

          .form-label {
            margin-bottom: 0;
          }

          input {
            height: 30px;
            width: 60px;
          }

          select {
            height: 45px;
            width: fit-content;
          }
        }

        .timeSelect {
          height: auto !important;
          padding-right: 25px;
          margin-left: .5rem;
        }

        .name,
        .price {
          font-weight: bold;
          font-size: 1.25em;
          margin-bottom: 0.5rem;
        }

        .price {
          margin-bottom: 0;
        }

        .extras {
          .form-check-input:checked {
            background-color: rgb(57, 57, 192);
            border-color: rgb(57, 57, 192);
          }

          .form-check-input:focus {
            border-color: rgb(57, 57, 192);
            outline: 0;
            box-shadow: 0 0 0 0.25rem rgb(57 57 192 / 25%);
          }
        }

        .image {
          margin-bottom: 0.5rem;
        }

        .desc {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;

          &.active {
            display: block;
          }
        }
      }

      .seeMore {
        text-decoration: underline;
      }

      .btn {
        font-weight: bold;
        padding: 0.5rem 1.5rem;
        white-space: nowrap;
      }
    }
  }
}

.imageSliders {

  .modal-content {
    background-color: transparent;
    border: none;

    .btn-close {
      background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
      opacity: 1;
      position: relative;
      padding: .5em;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: white;
        z-index: -1;
        border-radius: 50%;
      }
    }

    .slick-prev:before,
    .slick-next:before {
      opacity: 1;
    }

    .modal-header {
      color: white;
    }

    .modal-body {

      .image {
        height: 75vh;
        object-fit: contain;
        margin: auto;
        width: 99%;
      }
    }

    .slick-dots li.slick-active button:before {
      color: white;
      opacity: 1;
    }

    .slick-dots li button:before {
      color: white;
      opacity: .5;
    }
  }
}

.extraItem {
  list-style-type: disc;
  padding-left: 1em;
}

.mainImage {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  flex-wrap: wrap;
  gap: .5rem;
  align-items: center;

  .image {
    position: relative;


    img {
      max-height: 200px;
      object-fit: contain;
      width: 100%;
      max-width: 250px;
    }

    @include breakpoint(untilTablet) {
      margin-right: 0;
      margin: auto;
      width: 100%;

      img {
        max-width: none;
        object-fit: cover;
      }
    }

    .zoomIn {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      font-size: 24px;
      height: 100%;
      background-color: rgba($color: black, $alpha: 0.05);
      transition: all .2s;

      &:hover {
        background-color: rgba($color: black, $alpha: 0.3);
        font-size: 28px;
      }

      svg {
        path {
          fill: white;
        }
      }
    }
  }
}

.paxInput {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  margin-right: 1rem;

  .paxText {
    white-space: nowrap;
  }

  input {
    margin-left: .5rem;
    display: inline-flex;
    width: 80px;
  }
}